<template>
  <overlay
    class-name="content-wrapper"
    opacity="0.86"
    :show="loading"
  >
    <page-header
      screen-name="Adesão Nacional"
      :link-items="linkItems"
    />

    <main
      class="card p-2"
    >
      <section v-if="filteredCompanies.length > 0">
        <div class="d-flex">
          <h2 class="text-custom-blue mb-2 mr-auto">
            Grupo: {{ groupCompany.descricao }}
          </h2>
          <div class="mt-1">
            <b-button
              v-if="podeEditar"
              class="ml-auto"
              variant="primary-button"
              @click.prevent="redirectToEdit"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
              />
              <span class="align-middle">Editar</span>
            </b-button>
          </div>
        </div>
        <span><b>Operador:</b> {{ groupCompany.sigla_operador }}</span><br>
        <span><b>Doses distribuídas:</b> {{ groupCompany.doses_totais }} | <b>Doses disponíveis:</b> {{ groupCompany.saldo_estimativa }}</span><br>
        <span><b>Última atualização:</b> {{ groupCompany.ultima_alteracao }} | <b>Atualizado por:</b> {{ groupCompany.nome_pessoa_alteracao }}</span>
      </section>

      <Filters
        class-name="mt-2"
        :disable-hr="true"
        :disable-button-search="loading"
        @actionSearch="handleSubmitFormFilters"
        @actionClear="clearFilters"
      >
        <validation-observer ref="formFilters">
          <b-form>
            <b-row class="align-items-center">
              <b-col
                sm="12"
                lg="6"
              >
                <b-form-group
                  label="Nome da empresa"
                  label-for="companyName"
                  label-class="font_size_label"
                >
                  <b-form-input
                    id="companyName"
                    v-model="search.companyName"
                    maxlength="100"
                    placeholder="Nome fantasia da empresa"
                    autocomplete="off"
                  />
                </b-form-group>
              </b-col>

              <b-col
                sm="12"
                lg="6"
              >
                <b-form-group
                  label="CNPJ e CPF"
                  label-for="cpfCnpj"
                  label-class="font_size_label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="CNPJ e CPF"
                    rules="cpfCnpj"
                  >
                    <the-mask
                      id="cpfCnpj"
                      ref="inputMask"
                      v-model="search.companyCpfCnpj"
                      class="form-control"
                      :mask="['###.###.###-##', '##.###.###/####-##']"
                      placeholder="00.000.000/0000-00"
                      autocomplete="off"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </Filters>

      <section
        v-if="filteredCompanies.length > 0"
        class="mb-2"
      >
        <div
          v-for="empresa in filteredCompanies"
          :key="empresa.id_grupo_empresa"
          class="mb-2 rounded"
        >
          <b-list-group
            v-if="empresa.doses_distribuidas > 0"
            flush
            class="rounded border-bottom"
          >
            <b-list-group-item class=" align-items-center py-2 custom-blue list-group-item-head">
              <b-row>
                <b-col>
                  <h4 class="text-light p-0 m-0">
                    <strong class="text-light">
                      {{ empresa.nome_empresa }} |
                    </strong>
                    <span>
                      {{ empresa.cpf_cnpj_empresa | VMask(
                        empresa.cpf_cnpj_empresa.length <= 11
                          ? "###.###.###-##"
                          : "##.###.###/####-##"
                      ) }}
                    </span>
                  </h4>
                </b-col>

                <b-col class="text-right">
                  <div class="bg-light badge rounded-pill ">
                    <span class="text-custom-blue"><b>Doses distribuídas:</b> {{ empresa.doses_distribuidas }}</span>
                  </div>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item
              v-for="adesao in empresa.adesoes"
              :key="adesao.id_adesao"
              href="#"
              class=" py-2"
            >
              <b-row>
                <b-col>
                  <div class="mr-auto ">
                    <b-row>
                      <b-col
                        class="mx-0 px-0 lh-4"
                        cols="1"
                      >
                        <b-avatar
                          variant="light-custom-blue"
                          size="40"
                          class="ml-1"
                        >
                          <feather-icon
                            class="icone"
                            size="21"
                            icon="MapPinIcon"
                          />
                        </b-avatar>
                      </b-col>
                      <b-col class="text-left">
                        <span>
                          <span>{{ adesao.descricao_endereco }}</span>
                          <br>
                          <span class="mt-2">
                            {{ adesao.logradouro }}, {{ adesao.numero }}, {{ adesao.bairro }}, {{ adesao.cidade.nome }}, {{ adesao.cidade.uf }}
                          </span>
                          <br>
                          <strong>{{ adesao.unidade.descricao }}</strong>
                        </span>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
                <b-col cols="4">
                  <div class="text-right">
                    <b-row
                      v-for="vacina in adesao.adesao_vacina"
                      :key="vacina.id_adesao_vacina"
                    >
                      <b-col>
                        <span>{{ vacina.nome_tecnico }} <br></span>
                      </b-col>
                      <b-col cols="4">
                        <div>
                          <img
                            class="text-custom-blue mr-1"
                            :src="VaccineIcon"
                            alt="vaccineIcon"
                            width="18"
                            height="18"
                          >
                          <span class="text-custom-blue font-weight-bolder h3 m-0">{{ vacina.saldo }}</span>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </div>
      </section>

      <section
        v-if="filteredCompanies.length === 0"
        class="text-center mx-2 mt-5"
      >
        <p class="table-empty">
          Não há dados a serem exibidos.
        </p>
      </section>
    </main>
  </overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BListGroup,
  BListGroupItem,
  BAvatar,
  BForm,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { cpfCnpj } from '@validations'
import moment from 'moment'
import { TheMask, mask } from 'vue-the-mask'
import { strClear, stringNormalizeClear, spaceClear } from '@core/utils/utils'
import VaccineIcon from '@/assets/custom-icons/vaccine.svg'
import LocationPinIcon from '@/assets/images/icons/locationPin.svg'
import { actions, subjects } from '@/libs/acl/rules'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'
import { getAdesaoNacionalGrupoOperadorCampanha } from '@/views/custom-pages/adesao-nacional/requests'
import { warningInfoMessage, warningMessage } from '@/libs/sweetalerts'
import Filters from '@/views/components/custom/filters/Filters.vue'

export default {
  title: 'Visualizar adesão nacional',

  directives: {
    mask,
  },

  components: {
    BFormGroup,
    BFormInput,
    BForm,
    ValidationObserver,
    ValidationProvider,
    Filters,
    Overlay,
    TheMask,
    PageHeader,
    BRow,
    BCol,
    BButton,
    BListGroup,
    BListGroupItem,
    BAvatar,
  },

  data() {
    return {
      moment,
      cpfCnpj,
      VaccineIcon,
      LocationPinIcon,
      linkItems: [
        {
          name: 'Adesão Nacional',
          active: true,
          routeName: 'listar-adesao-nacional',
        },
        {
          name: 'Visualizar',
          active: true,
        },
      ],
      groupCompany: {
        empresas: [],
        descricao: '...',
        sigla_operador: '...',
        ultima_alteracao: '...',
        nome_pessoa_alteracao: '...',
        saldo_estimativa: 0,
        doses_totais: 0,
        id_departamento: '',
      },
      loading: true,
      alertWarning: true,

      search: {
        companyName: '',
        companyCpfCnpj: '',
      },

      filteredCompanies: [],
    }
  },
  computed: {
    podeEditar() {
      return this.$can(actions.ATUALIZAR, subjects.TIPO_ATUACAO_ADESAO_BASE_NACIONAL_COORDENADOR)
        || this.$can(actions.ATUALIZAR, subjects.TIPO_ATUACAO_ADESAO_BASE_NACIONAL_OPERADOR)
    },

    getItemInStore() {
      return this.$store.getters['adesaoNacionalViewState/getPurchaseView']
    },

    getDisableButtonClear() {
      return [
        this.search.companyName === '',
        this.search.companyCpfCnpj === '',
      ].every(field => field === true)
    },
  },

  created() {
    if (!this.getItemInStore) {
      this.redirectToMainPage()

      return false
    }

    return this.handleGetInfoPurchases()
  },

  methods: {
    async handleGetInfoPurchases() {
      this.loading = true

      this.clearGroupCompany()

      const params = {
        idGrupoOperador: this.getItemInStore.id_grupo_operador,
        idCampanha: this.getItemInStore.id_campanha,
        nomeEmpresa: this.search.companyName,
        cpfCnpjEmpresa: this.search.companyCpfCnpj ? strClear(this.search.companyCpfCnpj) : null,
      }

      await getAdesaoNacionalGrupoOperadorCampanha(params)
        .then(response => {
          if (response.data.empresas_adesoes.length > 0) {
            this.fillData(response.data)
          }
        })
        .catch(error => {
          this.handleError(error.response)
        })

      this.loading = false
    },

    handleError(response) {
      const errors = response.status === 400 || response.status === 404

      if (errors) {
        warningInfoMessage(
          response.data.errors,
          'Confira os dados e depois salve o usuário.',
          'Entendido',
        )

        return
      }

      warningMessage('Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.')
    },

    handleSubmitFormFilters() {
      this.filteredCompanies = this.groupCompany.empresas

      if (!this.search.companyName && !this.search.companyCpfCnpj) {
        return this.clearFilters()
      }

      this.filteredCompanies = this.filteredCompanies.filter(item => {
        const companyName = stringNormalizeClear(spaceClear(item.nome_empresa))
        const searchCompanyName = stringNormalizeClear(spaceClear(this.search.companyName))

        const matchNome = this.search.companyName
          ? companyName.toLowerCase().includes(searchCompanyName.toLowerCase())
          : true

        const matchCpfCnpj = strClear(this.search.companyCpfCnpj)
          ? item.cpf_cnpj_empresa.includes(strClear(this.search.companyCpfCnpj))
          : true

        return matchNome && matchCpfCnpj
      })

      return true
    },

    clearGroupCompany() {
      this.groupCompany = {
        empresas: [],
        descricao: '...',
        sigla_operador: '...',
        ultima_alteracao: '...',
        nome_pessoa_alteracao: '...',
        saldo_estimativa: 0,
        doses_totais: 0,
      }
    },

    clearFilters() {
      this.$refs.inputMask.lastValue = ''
      this.$refs.inputMask.display = ''

      this.search = {
        companyName: '',
        companyCpfCnpj: '',
      }

      this.filteredCompanies = this.groupCompany.empresas
    },

    fillData(data) {
      this.groupCompany.descricao = data.descricao_grupo
      this.groupCompany.sigla_operador = data.sigla_dr_operador
      this.groupCompany.saldo_estimativa = data.doses_disponiveis_grupo_operador
      this.groupCompany.doses_totais = data.doses_distribuidas
      this.groupCompany.empresas = data.empresas_adesoes
      this.groupCompany.ultima_alteracao = moment(data.ultima_atualizacao).format('DD/MM/YYYY, HH:mm')
      this.groupCompany.nome_pessoa_alteracao = data.usuario_alterador.nome
      this.groupCompany.id_grupo_operador = data.id_grupo_operador
      this.groupCompany.id_campanha = data.id_campanha

      this.filteredCompanies = data.empresas_adesoes
      this.groupCompany.id_departamento = data.id_departamento
    },
    redirectToEdit() {
      const item = {
        id_grupo_operador: this.groupCompany.id_grupo_operador,
        id_campanha: this.groupCompany.id_campanha,
        id_departamento: this.groupCompany.id_departamento,
      }

      this.$store.commit('adesaoNacionalViewState/setPurchaseUpdate', item)

      this.$router.push({ name: 'edit-adesao-nacional' })
    },

    redirectToList() {
      this.$router.push(
        {
          name: 'listar-adesao-nacional',
        },
      )
    },

    redirectToMainPage() {
      this.$store.commit('adesaoNacionalViewState/setPurchaseView', null)
      this.$router.replace({ name: 'listar-adesao-nacional' })
    },
  },
}
</script>

<style lang="scss" scoped>
 .shadow {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
 }
 .icon-location {
    max-width: 30px;
    margin-top: 5px;
  }
 .with-columm-input {
    max-width: 150px;
  }

  .list-group .list-group-item-head:hover {
    background-color: #115EAD;
  }

  .lh-4 {
    line-height: 4rem;
  }

</style>
