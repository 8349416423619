import axiosIns from '@/libs/axios'
import rotasAPI from '@/router/rotasAPI'

export const getNationalCampaigns = params => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.campanhasAdesaoNacional(), { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getCampaignsOperatorGroups = params => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.adesaoNacionalGrupos(), { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getNationalPurchaseGroups = params => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.gruposAdesaoNacional(), { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getOperatorGroupCampaign = params => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.grupoOperadorCampanha(), { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getDepartmentsOperatorGroups = () => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.departamentosGrupoOperador())
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getEstimativaCampanhaNacionalGroup = ({ idCampanha, idGrupoOperador }) => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.estimativaCampanhaNacionalGroup(idCampanha, idGrupoOperador))
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getCompaniesAddressesGroup = params => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.enderecosEmpresasGroup(), { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getAdesaoNacionalGrupoOperadorCampanha = params => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.adesaoNacionalGrupoOperadorCampanha(), { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const removeNationalPurchase = params => new Promise((resolve, reject) => {
  axiosIns
    .delete(rotasAPI.adesaoNacional(), { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})
